/* mixins */
/* Custom mixins */
/* inspired by bootstrap */
.shop-phone {
  float: left;
  padding: 5px 0 10px;
  line-height: 18px; }
  @media (max-width: 767px) {
    .shop-phone {
      display: none; } }
  .shop-phone i {
    font-size: 21px;
    line-height: 21px;
    padding-right: 7px; }
  @media (max-width: 991px) {
    .shop-phone.is_logged {
      display: none; } }

#contact-link {
  float: right; }
  @media (max-width: 767px) {
    #contact-link {
      text-align: center; } }
  #contact-link a {
    display: block;
    font-weight: bold;
    padding: 8px 10px 11px 10px;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    line-height: 18px; }
    @media (max-width: 767px) {
      #contact-link a {
        font-size: 11px;
        padding-left: 5px;
        padding-right: 5px; } }
    #contact-link a:hover, #contact-link a.active {
      background: #2b2b2b; }
  @media (max-width: 767px) {
    #contact-link.is_logged {
      display: none; } }

@media (max-width: 767px) {
  #contact_block {
    margin-bottom: 20px; } }

#contact_block .label {
  display: none; }

#contact_block .block_content {
  color: #888888; }

#contact_block p {
  margin-bottom: 4px; }

#contact_block p.tel {
  font: 400 17px/21px "Kaleko 105 Round W01 Book", Helvetica, sans-serif;
  color: #333;
  margin-bottom: 6px; }
  #contact_block p.tel i {
    font-size: 25px;
    vertical-align: -2px;
    padding-right: 10px; }
